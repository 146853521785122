import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

export default function CartButton(){

  const nav = useNavigate()
  const { items } = useSelector(state => state.cart)
  const btnBgColor = items.length === 0 ? 'none' : 'white'
  
  return (
    <div className="d-flex ms-0 me-lg-3 mb-3 mb-lg-0">
      <button 
        onClick={() => nav("/cart")} 
        className="btn btn-outline-success d-md-block mt-3 mt-lg-0" 
        type="button">
          <i className="bi bi-cart4"></i>
          <span className="mx-2">Checkout</span>
          <span className={`badge text-success bg-${btnBgColor}`}>{items.length}</span>
      </button>
    </div>   
  )
}