import { Routes, Route, useLocation} from "react-router-dom"
import Home from "./pages/Home"
import Single from "./pages/Single"
import Cart from "./pages/Cart"
import { useSelector, useDispatch } from "react-redux"
import { actions as cartActions } from "./global/slices/cartSlice";
import { useEffect } from "react"
import Navbar from "./components/nav/Navbar"

export default function App() {

    const dispatch = useDispatch(0)
    const {items} = useSelector(state => state.cart)    
    const location = useLocation()
    
    useEffect(() => { dispatch(cartActions.calculateCartNumbers()) }, [dispatch, items]) // watch items, fire every time items changes
    useEffect(() => { window.scrollTo(0,0) }, [location]) //watch location, fire every time location changes

    return (
        <div className="wrapper bg-dark text-white">
            <Navbar title="React Shop"/>
            
            <div className="container mt-5 py-5 px-3 px-md-5">                  
                <Routes>
                    <Route path="/" element={<Home/>} />             
                    <Route path="/single/:id" element={<Single/>} />             
                    <Route path="/cart" element={<Cart/>} />             
                </Routes>
            </div>
        </div>      
    );
}


