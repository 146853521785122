import { createSlice } from "@reduxjs/toolkit"

export const { actions, reducer } = createSlice({ // destructured export const slice...
    name : "cart",
    initialState : {
        items : [], // [] array of products in the cart
        cartNumbers : {
            subtotal : 0,
            shipping : 0,
            tax : 0,
            total : 0
        }
    },
    reducers : {
        addToCart(state, {payload:item}){
            // Push to cart
            state.items.push({...item, quantity : 1})

            
        },
        removeFromCart(state, {payload:item}){
            // Remove this item from cart array
            const index = state.items.indexOf((i) => i.id === item.id)
            state.items.splice(index,1)

        },
        incrementItemQuantity(state, {payload:{item, amount}}){
            // Increment the correct item from the store by the amout given (could be positive or negative)
            state.items = state.items.map((i) => (
                (i.id === item.id) ? ({...i, quantity: i.quantity + amount}) : i)
            ).filter(i => i.quantity > 0) //this filter removes from cart array items that have qty 0            
        },
        calculateCartNumbers(state){
          
            let subtotal = 0, shipping = 0, tax = 0, total = 0
            let itemShippingCost = 2

            for (const item of state.items) {

                // subtotal = price x quantity
                subtotal += +(parseFloat(item.price * item.quantity)).toFixed(2)
                
                // shipping = number of items x $2
                shipping += +(parseFloat(item.quantity * itemShippingCost)).toFixed(2)
                     
            }

            // tax = 10% of subtotal
            tax = (subtotal * 0.1).toFixed(2)

            // total = subtotal + shipping + tax
            total = (parseFloat(subtotal) + parseFloat(shipping) + parseFloat(tax)).toFixed(2)

            // reset

            state.cartNumbers = { subtotal, shipping, tax, total }
        }
    }
})