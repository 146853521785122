import { useSelector, useDispatch } from "react-redux";
import { actions as productActions } from "../../global/slices/productsSlice";

export default function SearchBar(){

  const { searchTerm } = useSelector(state => state.products)

  const dispatch = useDispatch()

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="d-flex ms-0 ms-lg-3 me-lg-1 mb-3 mb-lg-0">
        <input 
            type="search" 
            placeholder="Search products"
            className="form-control ms-md-auto me-2"
            onChange={(e) => {dispatch(productActions.setSearchTerm(e.target.value))}} 
            value={searchTerm}
            />
      </form>
    </div> 
  );
}