import { createSlice } from "@reduxjs/toolkit"
import data from "../../data"
import { uniq, sortBy } from "lodash"
import { loremIpsum } from "lorem-ipsum"
import { stringSimilarity as getSimScore } from "string-similarity-js"

const DEFAULT_CATEGORY = "All"

data.forEach(item => item.description = loremIpsum())

const categories = [DEFAULT_CATEGORY,...(uniq(data.map(product => product.category))).sort()]

export const { actions, reducer } = createSlice({
    name : "products",
    initialState : {
        products: data, productsFromSearch : data, 
        categories : categories,
        selectedCategory : DEFAULT_CATEGORY,
        single : data[0], singleSimilarProducts : [],
        searchTerm : ""
    },
    reducers : {
        setSearchTerm(state, {payload:searchTerm} ) { // {payload} is the same as action.payload // payload:searchTerm reassigns the value to searchTerm for cleaner code        
            // Reset Nav Bar
            state.productsFromSearch = state.products
            state.selectedCategory = DEFAULT_CATEGORY
            //
            state.searchTerm = searchTerm
            if (searchTerm.length > 0) {
                // Search Operations
                state.productsFromSearch.forEach(product => {
                product.simScore = getSimScore(`${product.name} ${product.category}`, searchTerm)                     
                })
                state.productsFromSearch = sortBy(state.productsFromSearch, "simScore").reverse()  //reverse array results since sortBy is form lowest ot highest and we want the exact opposite            
            } else {
                state.productsFromSearch = state.products
            }
        },
        setSelectedCategory(state, {payload:category}) {
            state.searchTerm = ""
            state.selectedCategory = category            
            state.productsFromSearch = state.products.filter( product => category === DEFAULT_CATEGORY ? true : product.category === category)          
        },
        setSingle(state, {payload:id}){            
            const product = state.products.find(product => product.id === +id) //remember to cast the passed id argument from string to integer using + // use find to return actual elment ... filter method returns an array !!!
            state.single = product   

            // get the similar products here   
            state.singleSimilarProducts = state.products.filter( p =>( p.category === product.category) && (p.id !== product.id))            
        },
      

    }
})

