import { useNavigate } from "react-router-dom"

export default function CartBuyButton({title="Buy Now"}) {
    
    const nav = useNavigate()
    const buy = () => {
        // eslint-disable-next-line no-restricted-globals
        if (window.confirm("Would You Like To Place Your Order?")) {
            window.alert("Order Placed Successfully!")
            nav("/")
            window.location.reload()
        }
    }
    
    return (        
        <div className="row d-block my-4 mx-1">
            <button onClick={buy} className="btn btn-success fw-bolder">
                {title}
            </button>
        </div>
    )
}