import NoContent from "../components/extra/NoContent"
import CartItem from "../components/cart/CartItem"
import CartNumbers from "../components/cart/CartNumbers"
import CartBuyButton from "../components/cart/CartBuyButton"
import { useSelector } from "react-redux"
import Condition from "../components/extra/Condition"




export default function Cart(){

    const { items } = useSelector(state => state.cart)

    return <Condition 
            test={items.length === 0}
            success={<NoContent text="Nothing in your Cart" btnText="Browse Products"/>}
            fail={  <div className="row py-3">
                        <div className="col-11 col-md-10 col-xl-8 min-h-100 mx-auto bg-white p-8 rounded">
                            <div id="cart" className="p-3 bg-white text-dark my-3 my-md-0 rounded-top">
                                <h4 className="mb-3 px-1">Cart</h4>
                            </div>                
                            <ul className="list-group mb-3 cart-list">                    
                                {items.map(item => <CartItem key={item.id} item={item} />)}                    
                            </ul>
                            <CartNumbers/>
                            <CartBuyButton/>
                        </div>
                    </div>
            }
    />
}