import { useNavigate, useLocation } from "react-router-dom"
import CategorySelector from "./CategorySelector"
import SearchBar from "./SearchBar"
import CartButton from "./CartButton"
import Condition from "../extra/Condition"

export default function Navbar({title=""}) {

const nav = useNavigate()
const { pathname } = useLocation()
return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark border-bottom border-gray">
        <div className="container-fluid">
            <span onClick={() => nav("/")} id="name" className="navbar-brand fw-bold pointer">
                {title}
            </span>       
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">       
                <Condition test={(pathname === "/")} 
                success={ <><CategorySelector /><SearchBar /></> } />       {/* there is no need to add fail prop */}          
                <CartButton />   
            </div>
        </div>
    </nav>
)
}