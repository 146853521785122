const data = [
    { "id": 1, "price": 486, "name": "Modest Warm Coat", "category": "Jackets", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 2, "price": 81, "name": "Comfy Winter Jacket", "category": "Jackets", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 3, "price": 119, "name": "Mens Thick Jacket", "category": "Jackets", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 4, "price": 226, "name": "Teal Parka", "category": "Jackets", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 5, "price": 302, "name": "Women's Gray Coat", "category": "Jackets", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 6, "price": 287, "name": "Minimalist Golden Scarf", "category": "Scarfs", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 7, "price": 212, "name": "Blue Luxury Scarf", "category": "Scarfs", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 8, "price": 73, "name": "Coast Crimson Polyester Scarf", "category": "Scarfs", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 9, "price": 273, "name": "Blue Nero Luxurious Winter Scarf", "category": "Scarfs", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 10, "price": 266, "name": "Cashmere Wool Scarf", "category": "Scarfs", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 11, "price": 89, "name": "Heavy Weight Cotton/Polyester Gloves", "category": "Gloves", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 12, "price": 258, "name": "Beechfield Fingerless Winter Gloves", "category": "Gloves", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 13, "price": 199, "name": "Lined Waterproof Brown Ski Glove", "category": "Gloves", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 14, "price": 44, "name": "Majestic Hawk Armor Gloves", "category": "Gloves", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 15, "price": 304, "name": "Plaid Cotton Jazz Hat", "category": "Hats", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 16, "price": 80, "name": "Vogue Women's Winter Hat", "category": "Hats", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 17, "price": 218, "name": "Vintage Warm Beanie", "category": "Hats", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 18, "price": 353, "name": "Extreme Winter Comfy Socks", "category": "Socks", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 19, "price": 109, "name": "Men's Dry Outlander Socks", "category": "Socks", "description": "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" },
    { "id": 20, "price": 470, "name": "Maroon Warm Women's Socks", "category": "Socks", "description" : "New range of formal shirts are designed keeping you in mind. With fits and styling that will make you stand apart" }
]

export default data